import { useState, useEffect } from 'react'
import { Navigation } from './components/navigation'
import { Header } from './components/header'
import { Features } from './components/features'
import { About } from './components/about'
import { Contact } from './components/contact'
import { Gallery } from './components/gallery'
import { Testimonials } from './components/testimonials'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'
import React from 'react'
import { FaWhatsapp } from 'react-icons/fa' // Import the WhatsApp icon

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <div>
      <Navigation data={landingPageData.Navigation} />
      <Header data={landingPageData.Header} />
      <Gallery />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Testimonials data={landingPageData.Testimonials} />
      <Contact data={landingPageData.Contact} />

      {/* Floating WhatsApp Button with Text */}
      <a 
        href="https://api.whatsapp.com/send?phone=5561982965600" 
        target="_blank" 
        rel="noopener noreferrer"
        style={styles.whatsappButton}
      >
        <FaWhatsapp size={32} style={{ marginRight: '10px' }} />
        <span>Peça já</span>
      </a>
    </div>
  )
}

const styles = {
  whatsappButton: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#25D366',
    borderRadius: '50px',
    padding: '10px 20px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    color: '#fff',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: 'bold',
  }
}

export default App
