import React from 'react';

export const Contact = (props) => {
  return (
    <div>
      <div id='contact' className='text-center'>
        <div className='container'>
        <div className='section-title text-center'>
            <h2>Fale conosco</h2>
          </div>
          <div className='row'>
          <p>
                <span>
                  <i className='fa fa-map-marker'></i> Estamos na  
                </span>{' '}
                {props.data ? props.data.address : 'loading'}
              </p>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Telefone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
          
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.whatsapp : '/'}>
                      <i className='fa fa-whatsapp'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : '/'}>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
