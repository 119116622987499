import React from 'react';

export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        {/* <ParticlesBg type="cobweb" bg={{zIndex: 0, position:"absolute", top:0}} /> */}
        <div className='overlay'>
          <div className='container'>
          
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                </h1>
                <h3>{props.data ? props.data.paragraph : 'Loading'}</h3>
                <p>Nossa paixão pela comida árabe nos impulsiona a criar uma experiência única para nossos clientes. Utilizamos ingredientes frescos e selecionados, seguindo receitas tradicionais que passaram de geração em geração.</p>
                <a
                  href='https://instagram.com/manzilarabe'
                  target='_blank'
                  rel='noopener'
                  className='btn btn-custom btn-lg'
                >
                  <i className="fa fa-brands fa-instagram"></i> Instagram
                </a>{' '}
                 <a
                  className='btn btn-custom btn-lg'
                  href='https://api.whatsapp.com/send?phone=5561982965600'
                  target='_blank'
                  rel='noopener'
                  data-service='whatsapp'
                  role='button'><i className="fa fa-brands fa-whatsapp"></i> Fale conosco!</a>{' '}
                <a
                  href='data/manzil-cardapio.pdf'
                  target='_blank'
                  rel='noopener'
                  className='btn btn-custom btn-lg'
                >
                  <i className="fa fa-brands fa-list"></i> Cardápio
                </a>{' '}                
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
