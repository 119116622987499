import React from 'react';

export const Features = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>{props.data ? props.data.title : 'Loading'}</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.buttons.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6 col-md-4'>
                  {' '}
                  <a href={d.link} target='_blank' rel='noopener'><i className={d.icon}></i>
                  <h3>{d.title}</h3></a>
                  <p>{d.text}</p>
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
